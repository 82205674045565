import '../assets/style/tecnologiaAliada/tecnologia.css';
import ads from '../assets/img/meta.png';
import meta from '../assets/img/google.png';
import funil from '../assets/img/grafico funil.png';

function TecnologiaAliada() {
    return (
      <div class="wrapper4">
        <main>
            <section class="module parallax4 parallax-4">
                <div className='title-tecnologia'> Tecnologia aliada</div>
                <div className='context-tecnologia'> Possuímos diversos métodos de acompanhamento personalizados para acompanhar seu negócio e como ele está evoluindo.</div>
            </section>
        </main>
        <div className='container-imgs'>
          <img class="ads-img" src={meta} alt="ads" />
          <img class="ads-img" src={ads} alt="meta" />
          <img class="ads-img" src={funil} alt="funil" />
        </div>
      </div>
    );
  }
  
  export default TecnologiaAliada;
  