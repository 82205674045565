import '../assets/style/comecarConosco/comecarConosco.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

function ComecarConosco() {
    return (
      <div class="wrapper5">
        <main>
            <section class="module parallax5 parallax-5">
                <div>
                    <div className='title-comecar'> O que é preciso para começar conosco?</div>
                </div>
                <div className='container-sub-title-comecar'>
                    <div className='sub-title-comecar'> Analisaremos seu projeto e então agendaremos uma reunião para uma consultoria
                        gratuita. Temos alguns requesitos que são importantes mas não obrigatórios para uma qualificação, sendo eles:
                    </div>
                </div>
                <div className='container-content-comecar'>
                    <div className='content-comecar'> <FontAwesomeIcon className='icon-comecar' icon={faCircleCheck} style={{color: "#e37e23",}} />Disponibilidade de investir em ferramentas;</div>
                    <div className='content-comecar'> <FontAwesomeIcon className='icon-comecar' icon={faCircleCheck} style={{color: "#e37e23",}} />Disponibilidade de ampliar investimento em canais de mídia paga;</div>
                    <div className='content-comecar'> <FontAwesomeIcon className='icon-comecar' icon={faCircleCheck} style={{color: "#e37e23",}} />Disponibilidade para reuniões com especialista;</div>
                </div>
            </section>
        </main>
      </div>
    );
  }
  
  export default ComecarConosco;
  