import React from 'react';
import '../../assets/style/formulario/etapa1.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Etapa2 = ({ prevStep , nextStep, handleChange, dados }) => {
  return (
    <div className='input-container' id="etapa2">
      <div>
        <input
          type="text"
          name="segmento"
          value={dados.segmento}
          placeholder="Qual seu segmento"
          onChange={handleChange}
          maxLength={60}
          className='campo'
          />
      </div>
      <div>
        <input
          type="text"
          name="site"
          value={dados.site}
          placeholder="Qual seu site"
          onChange={handleChange}
          className='campo'
          maxLength={60}
          />
      </div>
      <div>
        <input
          type="text"
          name="cargo"
          value={dados.cargo}
          placeholder="Qual seu Cargo"
          onChange={handleChange}
          className='campo'
          maxLength={60}
        />
      </div>
      <div className='container-steps'>
        <button className='button-next-step' onClick={prevStep}><FontAwesomeIcon icon={faChevronLeft}/> Anterior</button>
        <button className='button-next-step' onClick={nextStep}>Seguinte <FontAwesomeIcon icon={faChevronRight}/></button>
      </div>
    </div>
  );
};

export default Etapa2;
