import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import "../../assets/style/formulario/editform.css"
import Cookies from 'js-cookie';

const EditForm = ({ editUser }) => {
  const [userData, setUserData] = useState({
    nome: '',
    email: '',
    telefone: '',
    segmento: '',
    site: '',
    cargo: '',
    instagram: '',
    qtdFuncionarios: '',
    investimentoMarketing: '',
    faturamentoMensal: '',
  });


  useEffect(() => {
    setUserData(editUser);
  }, [editUser]);

  const handleInputChange = (e) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = Cookies.get('token');
      const response = await fetch(`https://greatminds.com.br/editar/usuario/${editUser.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error("Erro interno no servidor");
      }
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = () => {
    window.location.reload();
  };

  return (
    <div className="edit-form-main-container">
      <div className='container-form-edit'>
        <h2 className='title-edit'>Editar Usuário</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-container-edit" >
            <div className='divisor-edit'>
                <label className='label-edit'>
                  Nome:
                  <input type="text" className='input-field-edit' name="nome" value={userData.nome} onChange={handleInputChange} />
                </label>
                <label className='label-edit'>
                  Email:
                  <input type="text" className='input-field-edit' name="email" value={userData.email} onChange={handleInputChange} />
                </label>
                <label className='label-edit'>
                  Telefone:
                  <InputMask
                    type="text"
                    name="telefone"
                    mask="(99) 99999-9999"
                    maskChar=" "
                    className='input-field-edit'
                    value={userData.telefone}
                    onChange={handleInputChange}
                  />
                </label>
                <label className='label-edit'>
                  Segmento:
                  <input type="text" className='input-field-edit' name="segmento" value={userData.segmento} onChange={handleInputChange} />
                </label>
                <label className='label-edit'>
                  Site:
                  <input type="text"  className='input-field-edit' name="site" value={userData.site} onChange={handleInputChange} />
                </label>
              </div>
              <div className='divisor-edit'>
                <label className='label-edit'>
                  Cargo:
                  <input type="text"  className='input-field-edit' name="cargo" value={userData.cargo} onChange={handleInputChange} />
                </label>
                <label className='label-edit'>
                  Instagram:
                  <input type="text" className='input-field-edit' name="instagram" value={userData.instagram} onChange={handleInputChange} />
                </label>
                <label className='label-edit'>
                  Quantidade de Funcionários:
                  <input type="text" className='input-field-edit' name="qtdFuncionarios" value={userData.qtdFuncionarios} onChange={handleInputChange} />
                </label>
                <label className='label-edit'>
                  Investimento em Marketing:
                  <input type="text" className='input-field-edit' name="investimentoMarketing" value={userData.investimentoMarketing} onChange={handleInputChange} />
                </label>
                <label className='label-edit'>
                  Faturamento Mensal:
                  <input type="text" className='input-field-edit' name="faturamentoMensal" value={userData.faturamentoMensal} onChange={handleInputChange} />
                </label>
              </div>
            </div>
            <div className='field-button-edit'>
              <button className="button-edit" type="submit">Enviar</button>
              <button className="button-edit" onClick={handleCancel}>Recusar</button>
            </div>
        </form>
      </div>
    </div>
  );
};

export default EditForm;
