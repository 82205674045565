import React, { useState } from 'react';
import Swal from 'sweetalert2';
import logo from '../../assets/img/LogoSemFundo.png';
import '../../assets/style/login/login.css';
import Cookies from 'js-cookie';

const AuthComponent = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      const response = await fetch('https://greatminds.com.br/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          usuario: username,
          senha: password,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        const receivedToken = data.token;
  
        if (receivedToken) {
          Cookies.set('token', receivedToken, { expires: 1 });
          window.location.reload();
        } else {
          Swal.fire({
            title: 'Erro!',
            text: 'Token inválido recebido do servidor.',
            icon: 'error',
          });
        }
      } else {
        const errorData = await response.json();
        const errorMessage = errorData.message || 'Erro desconhecido ao enviar dados.';
        Swal.fire({
          title: 'Erro!',
          text: `${errorMessage}`,
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: 'Erro ao enviar dados.',
        icon: 'error',
      });
    }
  };

  return (
    <div className='main-container-login'>
      <div className='container-login'>
        <div className='img-div-container'>
          <img className='logo-GreatMinds-login' src={logo} alt="GreatMinds Logo Login" />
        </div>
        <div className='inputs-login-cotainer'>
          <span>Login</span>
          <input
            type="text"
            className='input-login'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <span>Senha</span>
          <input
            type="password"
            className='input-login'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button onClick={handleLogin}>Enviar</button>
      </div>
    </div>
  );
};

export default AuthComponent;
