import ApiTabela from "../../components/tabela/apiTabela";

const Admin = () => {

  return (
    <div>
      <ApiTabela></ApiTabela>
    </div>
  );
};

export default Admin;
