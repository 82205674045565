import React from 'react';
import "../../assets/style/utils/checkText.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const CheckText = (props) => {
    const { label } = props;

    return (
        <div class="text-content-parallax2"> 
            <FontAwesomeIcon className='icon-check' icon={faCheck} style={{color: "#e37e23",}}/>
            {label}
        </div>
    );
}

export default CheckText;
