import React from 'react';
import logo from '../assets/img/Logo1.png';
import '../assets/style/navbar/navbar.css';

function NavBar() {

  const handleWhatsappClick = () => {
    const whatsappLink = 'https://api.whatsapp.com/send?phone=5511985501479&text=oi,%20vim%20do%20site%20e%20quero%20mais%20informa%C3%A7%C3%B5es%20para%20agendar%20minha%20reuni%C3%A3o';
    window.open(whatsappLink, '_blank');
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className='nav-Container'>
      <div className='container-img-nav'>
        <img className='logo-GreatMinds' src={logo} alt="GreatMinds Logo" />
      </div>
      <div className="links-Container">
        <div onClick={() => scrollToSection('home')} className='home'>HOME</div>
        <div onClick={() => scrollToSection('quemsomos')} className='nav-text'>QUEM SOMOS</div>
        <div onClick={() => scrollToSection('nossotime')} className='nav-text'>NOSSO TIME</div>
        <div onClick={() => scrollToSection('contato')} className='nav-text'>CONTATO</div>
      </div>
      <div className='container-botao-link' id="Whatsapp">
        <div className='botao-link' onClick={handleWhatsappClick}>
          FALAR COM ESPECIALISTA
        </div>
      </div>
    </div>
  );
}

export default NavBar;
