import React, { useState } from 'react';
import '../../assets/style/formulario/etapa1.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import InputMask from 'react-input-mask';

const Etapa1 = ({ nextStep, handleChange, dados }) => {
  const [erros, setErros] = useState({
    nome: '',
    email: '',
    site: '',
  });

  const handleNext = () => {
    const novosErros = {};
    
    if (!dados.nome) {
      novosErros.nome = 'Obrigatório';
    } else {
      novosErros.nome = '';
    }

    if (!dados.email) {
      novosErros.email = 'Obrigatório';
    } else {
      novosErros.email = '';
    }

    if (!dados.telefone) {
      novosErros.telefone = 'Obrigatório';
    } else {
      novosErros.telefone = '';
    }

    // Verifica se há algum erro
    if (Object.values(novosErros).some(erro => erro !== '')) {
      setErros(novosErros);
    } else {
      setErros({});
      nextStep();
    }
  };

  const isSubmitDisabled = dados.nome === '' || dados.email === '' || dados.telefone === '';

  return (
    <div className='input-container' id="etapa1">
      <div>
        <input
          type="text"
          name="nome"
          value={dados.nome}
          placeholder="Qual seu Nome"
          onChange={handleChange}
          maxLength={60}
          className={`campo ${erros.nome ? 'comErro' : ''}`}
        />
        {erros.nome && <p className="mensagemErro">{erros.nome}</p>}
      </div>
      <div>
        <input
          type="email"
          name="email"
          value={dados.email}
          placeholder="Seu melhor email"
          onChange={handleChange}
          maxLength={60}
          className={`campo ${erros.email ? 'comErro' : ''}`}
        />
        {erros.email && <p className="mensagemErro">{erros.email}</p>}
      </div>
      <div>
        <InputMask
          type="text"
          name="telefone"
          mask="(99) 99999-9999"
          maskChar=" "
          value={dados.telefone}
          placeholder="Whatsapp"
          onChange={handleChange}
          className={`campo ${erros.site ? 'comErro' : ''}`}
        />
        {erros.site && <p className="mensagemErro">{erros.site}</p>}
      </div>
      <div className='container-steps'>
        <button className='button-next-step' disabled={isSubmitDisabled} onClick={handleNext}> 
          Seguinte <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

export default Etapa1;
