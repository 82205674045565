import '../assets/style/formulario/formulario.css';
import Swal from 'sweetalert2';
import React, { useState } from 'react';
import Etapa1 from './utils/etapa1';
import Etapa2 from './utils/etapa2';
import Etapa3 from './utils/etapa3';


const Formulario = () => {
  const [etapa, setEtapa] = useState(1);

  const [dados, setDados] = useState({
    nome: '',
    email: '',
    telefone: '',
    segmento: '',
    site: '',
    cargo: '',
    instagram: '',
    qtdFuncionarios: '',
    investimentoMarketing: '',
    faturamentoMensal: '',
  });

  const nextStep = () => {
    setEtapa(etapa + 1);
  };

  const prevStep = () => {
    setEtapa(etapa - 1);
  };

  const handleChange = (e) => {
    setDados({
      ...dados,
      [e.target.name]: e.target.value,
    });
  };

  const handleWhatsappClick = () => {
    const calendarioLink = 'https://calendar.app.google/qrphfbdQqtw8nzRFA';
    window.open(calendarioLink, '_blank');
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://greatminds.com.br/cadastrar/usuario', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dados),
      });
  
      if (response.ok) {
        Swal.fire({
          title: 'Sucesso!',
          text: 'Dados enviados com sucesso!',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } else {
        Swal.fire({
          title: 'Erro!',
          text: 'Erro ao enviar dados.',
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: 'Erro ao enviar dados.',
        icon: 'error',
      });
    }
  };
  
  

  const isSubmitDisabled = dados.nome === '' || dados.email === '' || dados.telefone === '';

  return (
    <div className='wrapper' id='falarcomespecialista'>
      <main>
        <section className='module parallax6 parallax-6'>
          <div className='container-main'>
            <div className='title-form'>Preencha e agende uma consultoria gratuita</div>
            <div className='container-formulario' id="formulario">
              {etapa === 1 && <Etapa1 nextStep={nextStep} handleChange={handleChange} dados={dados} />}
              {etapa === 2 && <Etapa2 prevStep={prevStep} nextStep={nextStep} handleChange={handleChange} dados={dados} />}
              {etapa === 3 && <Etapa3 prevStep={prevStep} handleChange={handleChange} dados={dados} />}
              <form  className="container-submit" onSubmit={handleSubmit}>
                <button type="submit" className='button-submit' disabled={isSubmitDisabled} onClick={handleWhatsappClick}>Enviar</button>
              </form>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Formulario;
