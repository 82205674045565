import React from 'react';
import CountUp from 'react-countup';
import '../../assets/style/utils/info.css';
import { useInView } from 'react-intersection-observer';

const InfoVariante = (props) => {
  const { title, endValue, duration, label } = props;
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <div className={`container-nossos-numeros ${inView ? 'animate' : ''}`} ref={ref}>
      <div className="title-nossos-numeros">
        {title}<CountUp end={endValue} duration={duration}/>M
      </div>
      <div className="container-media-numeros">{label}</div>
    </div>
  );
};

export default InfoVariante;
