import '../assets/style/nossosClientes/nossosClientes.css';
import clientes from '../assets/img/cleintes.png';


function NossosClientes() {
    return (
      <div className='container-nossos-clientes'>
        <div className='title-clientes'>Nossos Clientes</div>
        <img className='logo-clientes' src={clientes} alt="clientes" />
      </div>
    );
  }
  
  export default NossosClientes;
  