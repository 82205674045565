import React, { useState } from 'react';
import "../../assets/style/tabela/tabela.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faRectangleXmark } from '@fortawesome/free-regular-svg-icons';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import EditForm from './editform';

const MyTable = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const itemsPerPage = 25;

  const filteredData = data.filter(item =>
    Object.values(item).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const chunkArray = (array, size) => {
    return Array.from({ length: Math.ceil(array.length / size) }, (v, index) =>
      array.slice(index * size, index * size + size)
    );
  };

  const chunkedData = chunkArray(filteredData, itemsPerPage);
  const totalPages = chunkedData.length;

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleDelete = (item) => {
    setDeleteConfirmation(item);
  };

  const handleLogout = () => {
    Cookies.remove('token');
    window.location.href = '/login';
  };

  const confirmDelete = () => {
    if (deleteConfirmation) {
      const token = Cookies.get('token');
      fetch(`https://greatminds.com.br/apagar/usuario/${deleteConfirmation.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
        .then(response => response.json())
        .then(data => {
          console.log('Usuário excluído com sucesso:', data);
          window.location.reload();
        })
        .catch(error => {
          console.error('Erro ao excluir usuário:', error);
        });

      setDeleteConfirmation(null);
    }
  };

  const cancelDelete = () => {
    setDeleteConfirmation(null);
  };

  return (
    <div className='main-tabela'>
      <div className='container-input'>
        <div className='container-logout'>
          <h1>Great Minds</h1>
          <FontAwesomeIcon  className="logout-icon" icon={faRightFromBracket} style={{ color: "black" }} onClick={handleLogout} /> 
        </div>
        <input
          className='input-tabela'
          type="text"
          placeholder="Buscar por..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className='context-tabela'>
        {filteredData.length > 0 ? (
          <div>
            <table className='tabela-container'>
              <thead>
                <tr className='container-title-tabela'>
                  <th>Nome</th>
                  <th>Telefone</th>
                  <th>Email</th>
                  <th>Segmento</th>
                  <th>Site</th>
                  <th>Cargo</th>
                  <th>Instagram</th>
                  <th>qtdFuncionarios</th>
                  <th>invetimentoMarketing</th>
                  <th>FaturamentoMes</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {chunkedData[currentPage - 1].map(item => (
                  <tr className='linha-branca' key={item.id}>
                    <td>{item.nome}</td>
                    <td>{item.telefone}</td>
                    <td>{item.email}</td>
                    <td>{item.segmento}</td>
                    <td>{item.site}</td>
                    <td>{item.cargo}</td>
                    <td>{item.instagram}</td>
                    <td>{item.qtdFuncionarios}</td>
                    <td>{item.investimentoMarketing}</td>
                    <td>{item.faturamentoMensal}</td>
                    <td className='container-actions'>
                      <button className="action-button" onClick={() => setEditUser(item)}><FontAwesomeIcon icon={faEdit} style={{ color: "black" }} /></button>
                      <button className="action-button" onClick={() => handleDelete(item)}><FontAwesomeIcon icon={faRectangleXmark} style={{ color: "black" }} /></button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {totalPages > 1 && (
              <div>
                <button onClick={() => handlePageChange(currentPage - 1)}>Anterior</button>
                <span>Página {currentPage} de {totalPages}</span>
                <button onClick={() => handlePageChange(currentPage + 1)}>Próxima</button>
              </div>
            )}
          </div>
        ) : (
          <p>Nenhum resultado encontrado.</p>
        )}
      </div>
      {deleteConfirmation && (
        <div className="delete-confirmation-modal">
          <div className='container-modal'>
            <div>
              <p>Deseja realmente apagar o usuário {deleteConfirmation.nome}?</p>
            </div>
            <div>
              <button onClick={confirmDelete}>Sim</button>
              <button onClick={cancelDelete}>Cancelar</button>
            </div>
          </div>
        </div>
      )}

      {editUser && (
        <EditForm editUser={editUser}/>
      )}
    </div>
  );
};

export default MyTable;
