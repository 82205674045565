import '../assets/style/timeProffisional/timeProffisional.css';
import DropDown from './utils/dropdown';
// import DropdownWithDetails from './utils/dropdown';

function TimeProfissional() {
    return (
      <div class="wrapper3" id='nossotime'>
        <main>
          <section class="module parallax3 parallax-3">
            <div class='time-profissionais'>
              <div className='container-main-time-profissional'>
                <div class="title-parallax3">Um time de profissionais para você</div>
                  <div className='conatiner-drops-downs'>
                    <DropDown title="Social media" label="Um profissional responsável em produzir todo o conteúdo que será postado nas redes sociais"/>
                    <DropDown title="Produtor de conteúdo (fotografo e video maker)" label="Terá uma equipe de produção de conteúdo para tirarmos fotos e fazer filmagens estratégia para toda produção de conteúdo Produção sempre agendada previamente e esporádica"/>
                    <DropDown title="Designer" label="Nossa equipe de designers vai ajudar a criar o material que precisaremos e dar sua cara em todas as postagens e materiais que precisará"/>
                    <DropDown title="Gestor de trafego" label="O gestor de trafego é responsável por criar e gerenciar todas as campanhas necessárias para impulsionar sua empresa através dos canais de mídia paga"/>
                    <DropDown title="Profissional de Relações Públicas" label="Nosso profissional fica responsável por encontrar os melhores influencer para crescer sua empresa com velocidade divulgar sua marca"/>
                    <DropDown title="Copywriter" label="O Copywriter é o profissional responsável por escrever todas as cartas de vendas, anúncios e qualquer comunicação necessária para podermos falar da melhor maneira para vender mais"/>
                  </div>
                </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
  
  export default TimeProfissional;
  

  