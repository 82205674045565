import '../assets/style/planejamento/planejamento.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot } from '@fortawesome/free-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import React, { useState, useEffect } from 'react';


const handleWhatsappClick = () => {
  const whatsappLink = 'https://api.whatsapp.com/send?phone=5511985501479&text=oi,%20vim%20do%20site%20e%20quero%20mais%20informa%C3%A7%C3%B5es%20para%20agendar%20minha%20reuni%C3%A3o';
  window.open(whatsappLink, '_blank');
};


function Planejamento() {
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
      setFadeIn(true);
    }, []);
    return (
        <div class="wrapper">
        <main>
          <section class="module parallax parallax-1">
            <div class='container-title'>
              <div class="title">Tenha uma agência de vendas que faz você vender mais</div>
            </div>
            <div class='sub-title'>
              <div className='icon-text'>
                <FontAwesomeIcon className='icon' icon={faCircleDot} style={{color: "#e37e23",}} />
                Cansado de contratar agências que não entregam resultado?
              </div>
              <div className='icon-text'>
                <FontAwesomeIcon className='icon' icon={faCircleDot} style={{color: "#e37e23",}} />
                Quer crescer?
              </div>
            </div>
            <div className='solucao'>Somos sua Solução</div>
            <div className='container-comece'>
              <button className='comece-button' id="Whatsapp" onClick={handleWhatsappClick}> 
                <FontAwesomeIcon icon={faWhatsapp} style={{color: "#ffffff",}} />
                Comece agora mesmo
              </button>
            </div>
          </section>
        </main>
      </div>
    );
  }
  
  export default Planejamento;
  