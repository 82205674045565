import '../assets/style/rodape/rodape.css';
import '../assets/style/tecnologiaAliada/tecnologia.css';
import logo from '../assets/img/LogoBranco1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faPhoneFlip } from '@fortawesome/free-solid-svg-icons';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

function Rodape() {
    return (
      <div id='contato'>
        <main>
          <section class="module parallax8 parallax-8">
            <div className='container-context-footer'>
              <div className='footer-container'>
                <div className='itens-container'>
                  <div className='title-contentx-footer'>Contatos</div>
                  <div className='text-footer'><FontAwesomeIcon className='icon-footer' icon={faLocationDot} style={{color: "#e37e23",}}/> Rua dr Rubens gomes bueno 229</div>
                  <div className='text-footer'><FontAwesomeIcon className='icon-footer' icon={faPhoneFlip} style={{color: "#e37e23",}}/> 11 93902-7787</div>
                  <div className='text-footer'><FontAwesomeIcon className='icon-footer' icon={faEnvelopeOpen} style={{color: "#e37e23",}}/> atendimento@agenciagreatminds.com.br</div>
                </div>
              </div>
              <div className='footer-container'>
                <div className='itens-container-midia'>
                  <div className='title-contentx-footer'>Midias Sociais</div>
                  <div className='text-footer'><FontAwesomeIcon className='icon-footer' icon={faLinkedin} style={{color: "#e37e23",}}/> @agenciagreatminds</div>
                  <div className='text-footer'><FontAwesomeIcon className='icon-footer' icon={faInstagram} style={{color: "#e37e23",}}/> @agenciagreatminds</div>
                </div>
              </div>
              <div className='footer-container-img'>
                <img class="rodape-img" src={logo} alt="logo" />
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
  
  export default Rodape;
  