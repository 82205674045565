import '../assets/style/quemsomos/quemsomos.css';
import logoBranco from '../assets/img/LogoBranco1.png';
import CheckText from './utils/checkText';

function QuemSomos() {
    return (
      <div class="wrapper2" id='quemsomos'>
        <main>
          <section class="module parallax2 parallax-2">
            <div class='container-quem-somos'>
              <div className='container-img'>
                <img class="img" src={logoBranco} alt="logo" />
              </div>
              <div class='container-text-quem-somos'>
                <div class='container-title-subtitle'>
                  <div class="title-parallax2">Quem nós Somos?</div>
                  <div class="sub-title-parallax2">
                    A GreatMinds é criadora e pioneira no conceito “AGÊNCIA DE VENDAS” no Brasil e, através do nosso método GM–10, nos tornamos a maior aliada no aumento dos resultados de nossos clientes.
                  </div>
                </div>
                <div class="sub-title-laranja-parallax2"> Dores que nossos clientes tinham antes de nos contratar:</div>
                <div className='dores-divisor'>
                  <div className='container-1-text'>
                    <CheckText label="Lentidão de crescimento;"/>
                    <CheckText label="Baixo retorno pelo investimento;"/>
                    <CheckText label="Quer aumentar os canais de vendas;"/>
                    <CheckText label="Falta de uma equipe de marketing estratégico proximo para auxiliar na expansão;"/>
                  </div>
                  <div className='container-1-text'>
                    <CheckText label="Quer aumentar a base de clientes;"/>
                    <CheckText label="Demora na implementação de campanhas;"/>
                    <CheckText label="Demora na entrega de resultados;"/>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
  
  export default QuemSomos;
  

  