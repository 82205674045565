import Whatsapp from "../../components/botaoFlutuante";
import ComecarConosco from "../../components/comecarConosco";
import Formulario from "../../components/formulario";
import NavBar from "../../components/navBar";
import NossosClientes from "../../components/nossosClientes";
import NossosNumeros from "../../components/nossosNumeros";
import Planejamento from "../../components/planejamento";
import QuemSomos from "../../components/quemSomos";
import Rodape from "../../components/rodape";
import TecnologiaAliada from "../../components/tecnologiaAliada";
import TimeProfissional from "../../components/timeProfissional";

function Home() {
    return (
      <div className="App">
        <NavBar/>
        <Planejamento/>
        <NossosNumeros/>
        <QuemSomos/>
        <TimeProfissional/>
        <TecnologiaAliada/>
        <NossosClientes/>
        <ComecarConosco/>
        <Formulario/>
        <Rodape/>
        <Whatsapp/>
      </div>
    );
  }
  
  export default Home;
  