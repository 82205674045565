import '../../assets/style/utils/tripleContent.css';
import grafico from '../../assets/img/graficoAtt.png'; 

function TripleText() {
    return (
        <div class="container-long-text">
            <div className='container-texto-nossosnumeros'>
                <div class="container-content-triple">
                    <div class="muito-alem">muito além</div>
                    <div class="de-somente">de somente</div>
                    <div class="trafego">tráfego</div>
                </div>
                <div class="nossas-estrategias">
                    Nossas estratégias vão além do tráfego, 
                    ajudamos você a criar diversos canais de 
                    aquisição de
                </div>
                <div class="nossas-estrategias-x2"> clientes para sua empresa</div>
            </div>
            <div className='container-grafico'>
                <img class="grafico" src={grafico} alt="grafico" />
            </div>
        </div>
    );
  }
  
  export default TripleText;
  