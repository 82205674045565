import '../assets/style/whatsapp/botaoFlutuante.css'
import wtp from '../assets/img/whatsapp-logo-png.png'


const handleWhatsappClick = () => {
    const whatsappLink = 'https://api.whatsapp.com/send?phone=5511985501479&text=oi,%20vim%20do%20site%20e%20quero%20mais%20informa%C3%A7%C3%B5es%20para%20agendar%20minha%20reuni%C3%A3o';
    window.open(whatsappLink, '_blank');
  };


function Whatsapp() {
    return (
      <div>
        <img class="floating-button" src={wtp} alt="logo" onClick={handleWhatsappClick} />
      </div>
    );
  }
  
  export default Whatsapp;
  