import '../assets/style/nossosNumeros/nossosNumeros.css';
import Info from "./utils/info";
import InfoVariante from "./utils/infovariante";
import TripleText from './utils/tripleContent';

function NossosNumeros() {
    return (
      <div>
        <div class="container-main-title-nossos-numeros">
          <div class="main-title-nossos-numeros">Nossos Números este mês</div>
        </div>
        <div class="main-container-nossos-numeros">
            <Info title="K" endValue={136} duration={2} label="INVESTIMENTO" />
            <Info title="X" endValue={14} duration={2} label="ROI" />
            <InfoVariante title="+R$" endValue={2} duration={2} label="FATURAMENTO" />
        </div>
        <TripleText></TripleText>
      </div>
    );
  }
  
  export default NossosNumeros;
  