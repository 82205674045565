import React, { useState, useEffect } from 'react';
import Tabela from './tabela';
import Cookies from 'js-cookie';

const ApiTabela = () => {
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get('token');
        const response = await fetch('https://greatminds.com.br/tabela', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });
        if (!response.ok) {
          throw new Error('Erro ao buscar dados da API');
        }
        const data = await response.json();
        setApiData(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Tabela data={apiData} />
    </div>
  );
};

export default ApiTabela;
