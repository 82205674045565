import React from 'react';
import '../../assets/style/formulario/etapa1.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const Etapa3 = ({ prevStep, handleChange, dados }) => {
  const handleNumericChange = (e) => {
    const { value } = e.target;

    // Verifica se o valor contém apenas dígitos
    if (/^\d*$/.test(value)) {
      handleChange(e);
    }
  };

  return (
    <div className='input-container' id="etapa3">
      <div>
        <input
          type="text"
          name="instagram"
          value={dados.instagram}
          placeholder="Qual seu instagram"
          onChange={handleChange}
          className='campo'
          maxLength={60}
        />
      </div>
      <div>
        <input
          type="text"
          name="qtdFuncionarios"
          value={dados.qtdFuncionarios}
          placeholder="Quantos funcionários você tem"
          onChange={handleNumericChange}
          className='campo'
          maxLength={60}
        />
      </div>
      <div>
        <input
          type="text"
          name="investimentoMarketing"
          value={dados.investimentoMarketing}
          placeholder="Quanto você investe em marketing hoje"
          onChange={handleNumericChange}
          className='campo'
          maxLength={60}
        />
      </div>
      <div>
        <input
          type="text"
          name="faturamentoMensal"
          value={dados.faturamentoMensal}
          placeholder="Qual seu faturamento mensal"
          onChange={handleNumericChange}
          className='campo'
          maxLength={60}
        />
      </div>
      <div className='container-steps'>
        <button className='button-next-step' onClick={prevStep}><FontAwesomeIcon icon={faChevronLeft}/> Anterior</button>
      </div>
    </div>
  );
};

export default Etapa3;
