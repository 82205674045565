import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './components/auth/AuthContext';
import Home from './pages/Home/home';
import Login from './pages/login/login';
import Admin from './pages/Admin/admin';
import Cookies from 'js-cookie';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const token = Cookies.get('token');
        if (!token) {
          setIsAuthenticated(false);
          setLoading(false);
          return;
        }

        const response = await fetch('https://greatminds.com.br/validate-token', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          Cookies.remove('token');
        }
      } catch (error) {
        setIsAuthenticated(false);
        Cookies.remove('token');
      } finally {
        setLoading(false);
      }
    };

    checkAuthentication();
  }, []);

  if (loading) {
    return <div></div>;
  }

  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/login"
              element={
                isAuthenticated ? (
                  <Navigate to="/admin" replace />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/admin"
              element={
                isAuthenticated ? (
                  <Admin />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
