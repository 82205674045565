import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import "../../assets/style/utils/dropdown.css"

const TesteBotao = (props) => {
    const { title } = props;
    const { label } = props;
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropDown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='container-drop'>
            <button className={`botao-drop ${isOpen ? 'botao-drop-open' : ''}`} onClick={toggleDropDown}>
                <div className='title-botao-drop'>{title}</div>
                <div className='title-botao-drop'>
                    {isOpen ? <FontAwesomeIcon icon={faArrowUp} style={{color: "#ffffff",}}/> : <FontAwesomeIcon className='icon-comecar' icon={faChevronDown} style={{color: "#ffffff",}} />}</div>
            </button>
            {isOpen && (
                <div>
                    <div className='content-text-drop'>{label}</div>
                </div>
            )}
        </div>
    );
}

export default TesteBotao;
